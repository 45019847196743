@import '../../config/';
@import '../../mixins/typography';
@import '../../mixins/box-shadow';

.carousel {
  height: 20rem;
  background-color: map-get($map: $colors, $key: borderLight);
  border-radius: map-get($map: $spacing, $key: sitePadding) * 1.5;
  margin: 1rem 0 2rem 0;

  &-banner-wrapper {
    display: flex;
    transition: transform 0.5s ease-out;
  }

  &-container {
    position: relative;
    height: 100%;
    display: flex;
    overflow: hidden;

    // Displays control buttons on hover
    &:hover {
      .carousel-controls {
        opacity: 1;
      }
    }
  }

  &-banner {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    flex: 0 0 100%;
    transition: all 0.25s;
    overflow: hidden;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    background-color: map-get($map: $colors, $key: borderLight);

    &-image {
      height: 100%;
      position: relative;
    }

    &-copy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: map-get($map: $spacing, $key: sitePadding) * 2.5 map-get($map: $spacing, $key: sitePadding) * 3;
      background-color: map-get($map: $colors, $key: borderLight);

      h2 {
        @include heading();
        color: map-get($map: $colors, $key: copyDark);
      }

      p {
        @include copy();
        margin-top: 0.75rem;
        min-height: 5rem;
        color: map-get($map: $colors, $key: copyDark);
      }

      button {
        margin-top: 0.75rem;
        width: auto;
      }
    }

    &--primary {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      flex-wrap: wrap;

      svg {
        width: 7rem;
        height: auto;
      }

      @include breakpoint(large, max) {
        margin-top: 0.5rem;
        gap: 0.5rem;
      }
    }
  }

  &-controls {
    display: flex;
    position: absolute;
    top: 50%;
    width: 100%;
    opacity: 0;
    justify-content: space-between;
    transform: translateY(-50%);
    transition: opacity 0.25s ease-in-out;

    button {
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 100%;
      box-shadow: rgba(13, 13, 14, 0.08) 0px;
      background-color: map-get($map: $colors, $key: foreground);
      opacity: 0.7;
      transition: opacity 0.25s;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-pagination {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    gap: 0.5rem;
    bottom: 0%;
    right: 0;
    transform: translateX(0);

    &-indicator {
      display: none;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      border: none;
      background-color: map-get($map: $colors, $key: lighterGrey);
      cursor: pointer;
      height: auto;
      aspect-ratio: 1 / 1;
      border: 1px solid map-get($map: $colors, $key: copy);

      &.active {
        background-color: map-get($map: $colors, $key: primary);
      }
    }

    &-pause {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 100%;
      background-color: map-get($map: $colors, $key: lighterGrey);
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  @include breakpoint(medium, max) {
    margin: 0;
    margin-bottom: map-get($map: $spacing, $key: sitePadding);
    height: auto;

    &-banner {
      grid-template-columns: none;
      grid-template-rows: auto auto;

      &-image {
        min-height: 10rem;
        order: 1;
      }

      &-copy {
        width: 100%;
        min-height: 13rem;
        justify-content: flex-start;
        padding: map-get($map: $spacing, $key: sitePadding);
        order: 2;

        h2 {
          @include type($size: medium, $font: copyBold, $color: copyDark);
        }

        p {
          min-height: 3rem;
          margin-top: 0.5rem;
        }
      }
    }

    &-controls {
      display: none;
    }

    &-pagination {
      right: 0;
      transform: translateX(0);

      &-indicator {
        display: block;
        width: 0.5rem;
        height: 0.3rem;
        border-radius: 0.5rem;
        aspect-ratio: 0.5/0.2;
        opacity: 0.5;

        &.active {
          background-color: map-get($map: $colors, $key: primary);
          opacity: 1;
        }
      }

      &-pause {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .ratings {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    align-items: center;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    span {
      @include type($size: tiny, $color: copy);
    }

    @include breakpoint(large, max) {
      display: none;
    }
  }
}
